
        // Register service worker
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', async () => {
                try {
                    // Unregister any existing service workers first
                    const registrations = await navigator.serviceWorker.getRegistrations();
                    for (let registration of registrations) {
                        await registration.unregister();
                        console.log('ServiceWorker unregistered:', registration.scope);
                    }

                    // Register new service worker
                    const registration = await navigator.serviceWorker.register('/sw.js', {
                        scope: '/',
                        updateViaCache: 'none' // Don't cache the service worker file
                    });
                    console.log('ServiceWorker registration successful:', {
                        scope: registration.scope,
                        scriptURL: registration.active?.scriptURL || registration.installing?.scriptURL
                    });

                    // Handle updates
                    registration.addEventListener('updatefound', () => {
                        const newWorker = registration.installing;
                        console.log('ServiceWorker update found:', newWorker?.scriptURL);
                        
                        newWorker?.addEventListener('statechange', () => {
                            console.log('ServiceWorker state changed:', newWorker.state);
                            if (newWorker.state === 'activated') {
                                console.log('ServiceWorker successfully activated');
                            }
                        });
                    });

                    // Request notification permission
                    if ('Notification' in window) {
                        const permission = await Notification.requestPermission();
                        console.log('Notification permission:', permission);
                    }

                    // Setup offline/online handlers
                    window.addEventListener('online', async () => {
                        console.log('Application is online');
                        document.body.classList.remove('offline');
                        // Trigger background sync
                        try {
                            await registration.sync.register('sync-affirmations');
                            console.log('Background sync registered');
                        } catch (error) {
                            console.warn('Background sync registration failed:', error);
                        }
                    });

                    window.addEventListener('offline', () => {
                        console.log('Application is offline');
                        document.body.classList.add('offline');
                    });
                } catch (error) {
                    console.error('ServiceWorker registration failed:', error);
                    // Add visible error notification for users
                    const errorDiv = document.createElement('div');
                    errorDiv.className = 'service-worker-error';
                    errorDiv.textContent = 'Offline support is currently unavailable. Please refresh the page to try again.';
                    document.body.insertBefore(errorDiv, document.body.firstChild);
                }
            });
        } else {
            console.warn('ServiceWorker is not supported in this browser');
        }
    